import Vue from "vue";
import * as config from "@/config";
import * as commonJs from "./common.js";

Vue.mixin({
	computed: {
		//配置文件
		$config() {
			return config;
		},
		//共同方法
		$commonJs() {
			return commonJs;
		},
	},
});