<template>
  <div class="footer_box max_width_1200" id="footerIdBox">
    <div class="footer_box_qr">
      <p class="title">联系我们</p>
      <img src="../assets//img/qr.png" alt="" />
    </div>
    <p class="bot_text">copyright:姚柳婚恋关系研究院</p>
  </div>
</template>

<script>
export default {
  name: "MarriageFooter",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer_box {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer_box_qr {
    img {
      width: 144px;
      height: 144px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      margin: 10px;
    }
  }

  .bot_text {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    margin: 10px;
  }
}
</style>
