
<template>
  <div class="example-3d">
    <div class="huoke_swiper-prev">
      <img src="../assets/img/anniu.png" alt="" />
    </div>
    <div class="huoke_swiper-next">
      <img src="../assets/img/anniuyou.png" alt="" />
    </div>
    <swiper
      class="swiper"
      ref="mySwiper"
      :options="certificateSwiperOption"
      @mouseenter.native="enter"
      @mouseleave.native="leave"
    >
      <template v-for="(item, index) in swiperList">
        <swiper-slide :key="index">
          <img class="slide_img" :src="item.img" alt="" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-3d-coverflow",
  title: "3D Coverflow effect",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      certificateSwiperOption: {
        slideToClickedSlide: false,
        loop: true,
        speed: 400,
        autoplay: {
          delay: 3000,
        },
        breakpoints: {
          320: {
            slidesPerView: 7,
            spaceBetween: -100,
            slidesOffsetBefore: 10,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: -100,
            slidesOffsetBefore: -50,
          },
        },
        // slidesPerView: 7,
        centeredSlides: true,
        watchSlidesProgress: true,
        on: {
          setTranslate: function () {
            let slides = this.slides;
            for (let i = 0; i < slides.length; i++) {
              let slide = slides.eq(i);
              let progress = slides[i].progress;
              // 看清楚progress是怎么变化的
              // slide.html(progress.toFixed(2));
              slide[0].style.opacity = 1 - Math.abs(progress) / 6;
              slide.css({
                "box-shadow": "none",
                "transform-origin": "center center",
                transform:
                  "scale(" +
                  (1 - Math.abs(progress) / 8) +
                  ") translateX(" +
                  progress * -95 +
                  "px)",
              });

              if (progress.toFixed(2) == 1) {
                slide.css({
                  opacity: 0.4,
                });
              }
              if (progress.toFixed(2) == -1) {
                slide.css({
                  opacity: 0.4,
                });
              }
              if (progress.toFixed(2) == -2) {
                slide.css({
                  opacity: 0.3,
                });
              }
              // if (progress == 3) {
              //   slide.css({
              //     transform:
              //       "scale(" +
              //       (1 - Math.abs(progress) / 8) +
              //       ") translateX(" +
              //       -270 +
              //       "px)",
              //   });
              // }
              // if (progress == -3) {
              //   slide.css({
              //     transform:
              //       "scale(" +
              //       (1 - Math.abs(progress) / 8) +
              //       ") translateX(" +
              //       270 +
              //       "px)",
              //   });
              // }
            }
          },
          // setTransition: function (transition) {
          //   for (var i = 0; i < this.slides.length; i++) {
          //     var slide = this.slides.eq(i);
          //     slide.transition(transition);
          //   }
          // },
        },
        navigation: {
          nextEl: ".huoke_swiper-next",
          prevEl: ".huoke_swiper-prev",
        },
      },
    };
  },
  props: {
    swiperList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    enter() {
      this.swiper.autoplay.stop();
    },
    leave() {
      this.swiper.autoplay.start();
    },
    openVideo() {
      window.open(
        "http://video.newseasoft.com/shagua/1%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85.mp4",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.example-3d {
  // width: 1000px;
  height: 463px;
  padding-top: 70px;
  position: relative;
  // left: -200px;
}

.swiper {
  position: relative;

  .swiper-slide {
  }

  .swiper-slide-active {
    // .huoke_swiper-prev {
    //   display: block;
    // }
    // .huoke_swiper-next {
    //   display: block;
    // }
    .slide_img {
      border: 5px solid #7375d9;
    }
  }

  .swiper-pagination {
    // /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
    //   background-color: $white;
    // }
  }
}

.huoke_swiper-prev {
  // display: none;
  cursor: pointer;
  position: absolute;
  left: 120px;
  bottom: 40%;
  width: 40px;
  height: 40px;
  opacity: 1;
  z-index: 9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
  }
}
.huoke_swiper-next {
  z-index: 9;
  // display: none;
  cursor: pointer;
  position: absolute;
  right: 230px;
  bottom: 40%;
  width: 40px;
  height: 40px;
  opacity: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide_img {
  width: 206px;
  height: 399px;
  border-radius: 20px;
}

@media only screen and (max-width: 980px) {
  .example-3d {
    // width: 1000px;
    height: auto;
    padding-top: 30px;
    position: relative;
    // left: -30px;
    max-width: 90vw;
  }
  .slide_img {
    width: 106px;
    height: 199px;
  }

  .huoke_swiper-prev {
    cursor: pointer;
    position: absolute;
    left: 30px;
    bottom: 40%;
    width: 40px;
    height: 40px;
    opacity: 1;
    z-index: 9;
  }
  .huoke_swiper-next {
    z-index: 9;
    cursor: pointer;
    position: absolute;
    right: 30px;
    bottom: 40%;
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
</style>