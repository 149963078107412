import router from '../router'
import {
	BASE_URL, RICH_URL
} from "../config";


// 路由跳转
export function changeView(url, queryParams) {
	return router.push({
		path: url,
		query: queryParams,
	})
}

export function test() {
	console.log("test")
}
