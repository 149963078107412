<template>
  <div class="navbar_outer">
    <div class="navbar max_width_1200">
      <img class="navbar_logo" src="../assets/logo.png" alt="" />
      <div class="navbar_box">
        <template v-for="(item, index) in navList">
          <div @click="navbarClick(item)" :key="index" class="navbar_item">
            <span
              class="navbar_text"
              :class="navId == item.id ? 'navbar_text_active' : ''"
              >{{ item.name }}</span
            >
          </div>
        </template>
      </div>
    </div>
    <div class="navbar_mobile">
      <div class="header">
        <div @click="handlerClick()" class="navbar_logo">
          <img src="@/assets/logo.png" style="width: 100%; height: 100%" />
        </div>

        <div @click="menuClick()" class="menu_logo">
          <img
            :src="isNavbarShow ? menuClose : menuLogo"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div
        class="navbar_mobile_content"
        :class="isNavbarShow ? 'navbar_mobile_content_Show' : ''"
      >
        <div
          class="navbar_text_outer"
          v-for="(item, index) in navList"
          :key="index"
        >
          <div class="navbar_text">
            <div class="rowBox" @click="mobileRowClick(item)">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarriageHeader",
  data() {
    return {
      isNavbarShow: false,
      menuLogo: require("@/assets/img/menuLogo.svg"),
      menuClose: require("@/assets/img/menuClose.svg"),
      navList: [
        { id: 1, name: "首页", url: "", hash: '' },
        { id: 2, name: "专家", url: "", hash: 'zjId0' },
        { id: 3, name: "咨询师", url: "", hash: 'footerIdBox' },
        { id: 4, name: "工作坊", url: "", hash: 'zxszyId' },
        { id: 5, name: "教育", url: "", hash: 'staId' },
        { id: 6, name: "独立执业赋能", url: "", hash: 'asdfasdf' },
        { id: 7, name: "社会责任", url: "", hash: 'shzrId' },
      ],
      navId: 1,
    };
  },

  mounted() {},

  methods: {
    mClick(item) {
      location.hash = item.hash;
    },
    navbarClick(item) {
      this.navId = item.id;
      this.mClick(item);
    },
    handlerClick() {},
    // 手机端菜单点击
    menuClick() {
      this.isNavbarShow = !this.isNavbarShow;
    },
    mobileRowClick(item) {
      this.isNavbarShow = false;
      this.mClick(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar_mobile {
  display: none;
}

.navbar_outer {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  border-bottom: 2px solid #f0e1c4;
}
.navbar {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .navbar_logo {
    width: 198px;
    height: 43px;
  }

  .navbar_box {
    display: flex;
    align-items: center;
    .navbar_item {
      .navbar_text {
        cursor: pointer;
        font-size: 18px;
        // font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1c1d25;
        line-height: 26px;
        padding: 27px 0;
        margin: 0 18px;
        position: relative;
        border-bottom: 3px solid transparent;
      }

      .navbar_text_active {
        color: #cc831c;
      }

      .navbar_text:hover {
        color: #cc831c;
      }
    }
  }
}

// 手机端
@media only screen and (max-width: 920px) {
  .navbar {
    display: none;
  }

  .navbar_mobile {
    display: flex;
    padding: 10px 10px;
    // background: rebeccapurple;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .navbar_logo {
        width: 198px;
        height: 43px;
      }

      .menu_logo {
        width: 30px;
        height: 30px;
      }
    }

    .navbar_mobile_content {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      color: #000000;
      width: 100%;
      max-width: 100vw;
      z-index: 9999;
      transform: translateX(100%);
      transition: inherit;
      position: fixed;
      left: 0;
      top: 64px;
      height: calc(100vh - 70px);
      padding: 5px 20px;
      overflow-y: scroll;
      transition: all 0.3s ease;
      .navbar_text_outer {
        width: 100%;
        .navbar_text {
          cursor: pointer;
          text-align: left;
          padding: 16px 0;
          width: 100%;
          border-bottom: 1px solid RGBA(247, 247, 247, 1);
        }
      }
    }

    .navbar_mobile_content_Show {
      transition: inherit;
      transform: translateX(0);
      transition: all 0.3s ease;
    }
  }
}
</style>
