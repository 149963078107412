<template>
  <div>
    <keep-alive>
      <Header></Header>
    </keep-alive>
    <router-view />
    <keep-alive>
      <Footer></Footer>
    </keep-alive>
  </div>
</template>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
@import url("@/assets/css/base.css");
.max_width_1200 {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}

.max_width_1200_1 {
  max-width: 1640px;
  padding: 0 20px;
  padding-left: 240px;
  padding-right: 0;
  margin: 0 auto;
}
</style>
