<template>
  <div class="swiper-container">
    <swiper
      class="swiper-container_2"
      ref="mySwiper"
      :options="swiperOption"
      @mouseenter.native="enter"
      @mouseleave.native="leave"
    >
      <swiper-slide
        class="swiper-wrapper"
        v-for="(item, index) in loopSwiperList"
        :key="index"
      >
        <div :class="(index + 1) % 2 == 0 ? 'slide_block_change_p' : ''">
          <div class="slide_block">
            <div class="slide_block_top" :style="'background-color:' + item.textBgColor">
              <div>
                <p class="name">{{ item.name }}</p>

                <template
                  v-for="(descItem, descIndex) in item.desc
                    ? item.desc.split(',')
                    : []"
                >
                  <p :key="descIndex" class="desc">
                    {{ descItem }}
                  </p>
                </template>
              </div>
            </div>
            <div class="slide_block_bot">
              <img v-if="item.img" class="imgStyle" :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 奇数时添加一条 -->
      <swiper-slide v-if="loopSwiperList.length % 2 == 1">
        <div class="slide_block_change_p">
          <div class="slide_block">
            <div class="slide_block_top">
              <div>
                <p class="name">{{ loopSwiperList[0].name }}</p>
                <template
                  v-for="(descItem, descIndex) in loopSwiperList[0].desc
                    ? loopSwiperList[0].desc.split(',')
                    : []"
                >
                  <p :key="descIndex" class="desc">
                    {{ descItem }}
                  </p>
                </template>
              </div>
            </div>
            <div class="slide_block_bot">
              <img class="imgStyle" :src="loopSwiperList[0].img" alt="" />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "SwiperList",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    loopSwiperList: {
      require: false,
      default: () => [],
    },
  },
  watch: {
    loopSwiperList: {
      handler(newValue, old) {
        this.slideTo();
      },
      deep: true, // 是否深度监听
      immediate: true, // 是否在组件创建时立即执行回调函数
    },
  },
  data() {
    return {
      swiperOption: {
        speed: 3000, //匀速时间
        freeMode: true,
        loop: false,
        slidesOffsetBefore: 150, //左边偏移量
        autoplay: {
          delay: 2500, //停顿时间
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        breakpoints: {
          320: {
            //当屏幕宽度大于等于320
            slidesPerView: 1,
            spaceBetween: 30, //间隔
            slidesOffsetBefore: 100,
          },
          320: {
            //当屏幕宽度大于等于320
            slidesPerView: 1.85,
            slidesOffsetBefore: 100,
            spaceBetween: 30, //间隔
          },
          400: {
            //当屏幕宽度大于等于320
            slidesPerView: 2,
            slidesOffsetBefore: 100,
            spaceBetween: 30, //间隔
          },
          776: {
            //当屏幕宽度大于等于776
            slidesPerView: 2.8,
            slidesOffsetBefore: 150,
            spaceBetween: 30,
          },
          1000: {
            //当屏幕宽度大于等于1000
            slidesPerView: 3.3,
            spaceBetween: 30, //间隔
          },
          1200: {
            //当屏幕宽度大于等于1200
            slidesPerView: 4,
            spaceBetween: 30, //间隔
          },
          1400: {
            slidesPerView: 4.5,
            spaceBetween: 30, //间隔
          },
          1600: {
            slidesPerView: 5.5,
            spaceBetween: 30, //间隔
          },
          1800: {
            slidesPerView: 5.9,
            spaceBetween: 30, //间隔
          },
          2200: {
            //当屏幕宽度大于等于1200
            slidesPerView: 7,
          },
          2400: {
            slidesPerView: 7.8,
            spaceBetween: 30, //间隔
          },
        },
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        allowTouchMove: false, //不允许触摸滑动
        // centeredSlides: true, //当slide的总数小于slidesPerView时，slide居中
        nextTime: 0,
        nextTransForm: "",
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    slideTo() {
      if (this.loopSwiperList.length >= 8) {
        this.swiperOption.loop = true;
        let that = this;
        setTimeout(function () {
          if (that.$refs.mySwiper) {
            that.$refs.mySwiper.$swiper.slideNext();
          }
        }, 1000);
      }
    },
    enter() {
      console.log("enter");
      return;
      // console.log(this.$refs.mySwiper)
      let that = this;
      if (that.$refs.mySwiper) {
        this.nextTransForm = document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform;
        // 轮播图原本应移动到的位置
        let nextTransPosition =
          -1 *
          parseInt(
            document
              .getElementsByClassName("swiper-container_2")[0]
              .getElementsByClassName("swiper-wrapper")[0]
              .style.transform.split("translate3d(")[1]
              .split("px")[0]
          );

        // 鼠标悬浮时时轮播图位置
        let nowTransPosition =
          -1 *
          parseInt(
            window
              .getComputedStyle(
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName("swiper-wrapper")[0],
                false
              )
              ["transform"].split("1, ")[2]
              .split(",")[0]
          );
        // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
        let nowTransForm = window.getComputedStyle(
          document
            .getElementsByClassName("swiper-container_2")[0]
            .getElementsByClassName("swiper-wrapper")[0],
          false
        )["transform"];
        // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
        this.nextTime = 5500 * ((nextTransPosition - nowTransPosition) / 370);
        // 改变行内transform属性
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          nowTransForm;
        // 不写也没关系
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = "0ms";
        // this.swiper.autoplay.stop();
        that.$refs.mySwiper.$swiper.autoplay.stop();
      }
    },
    leave() {
      console.log("leave");
      return;
      let that = this;
      if (that.$refs.mySwiper) {
        // 恢复原样
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          this.nextTransForm;
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = this.nextTime + "ms";
        this.swiper.autoplay.start();
        that.$refs.mySwiper.$swiper.autoplay.start();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
$blockWidth: 300px;
$blockTopHeight: 160px;
$blockBotHeight: 380px;
$nameSize: 18px;
$descSize: 16px;
$marginBottom: 30px;
.slide_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 29px;

  .slide_block_top {
    width: $blockWidth;
    height: $blockTopHeight;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: $marginBottom;
    padding: 20px 15px;
    overflow: hidden;

    .name {
      font-size: $nameSize;
      font-weight: bold;
      color: #7375d9;
      line-height: 40px;
    }

    .desc {
      font-size: $descSize;
      color: #7375d9;
      line-height: 20px;
    }
  }

  .slide_block_bot {
    width: $blockWidth;
    height: $blockBotHeight;
    border-radius: 28px;
    overflow: hidden;
    margin-bottom: $marginBottom;
  }
  .imgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide_block_change_p {
  .slide_block {
    .slide_block_top {
      order: 1;
    }

    .slide_block_bot {
    }
  }
}

@media only screen and (max-width: 850px) {
  $blockWidth: 200px;
  $blockTopHeight: 150px;
  $blockBotHeight: 250px;
  $nameSize: 16px;
  $descSize: 14px;
  $marginBottom: 20px;

  .slide_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .slide_block_top {
      width: $blockWidth;
      height: $blockTopHeight;
      border-radius: 12px;
      margin-bottom: $marginBottom;
      padding: 10px 8px;
      overflow: hidden;

      .name {
        font-size: $nameSize;
        font-weight: bold;
        color: #7375d9;
        line-height: 20px;
      }

      .desc {
        font-size: $descSize;
        color: #7375d9;
        line-height: 20px;
      }
    }

    .slide_block_bot {
      width: $blockWidth;
      height: $blockBotHeight;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: $marginBottom;
    }
    .imgStyle {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
