<template>
    <div class="home_info">
        <div class="top_block" id="zjId0">
            <div class="top_title_box max_width_1200">
                <div class="top_title">
                    2024年和<span class="top_title_active">100位</span>专家&
                </div>
                <div class="top_title">
                    <span class="top_title_active">一线资深</span>心理咨询师一起
                </div>
                <span class="top_desc">探索适合中国婚恋关系咨询的最佳技术实践</span>
            </div>
            <div>
                <LoopSwiper :loopSwiperList="expertList"></LoopSwiper>
            </div>
        </div>

        <div class="top_title_box max_width_1200 bot_title_box">
            <div class="top_title">
                <span class="top_title_active">情绪取向</span>整合咨询框架
            </div>
            <p class="top_desc">
                适用于改善各类情绪情感及婚恋关系问题<br/>
                经过8-20次晤谈，70-75%的伴侣痛苦程度下降，90%的伴侣关系明显改善
            </p>
        </div>
        <div class="home_info_block2 max_width_1200">
            <div class="home_info_block2_img_box">
                <img
                    class="home_info_block2_img"
                    src="../assets/img/blck2Img.png"
                    alt=""
                />
            </div>

            <div class="home_info_block2_right">
                <div class="home_info_block2_right_item">
                    <p class="item_title">临床实证效果明显，可操作性强</p>
                    <p class="item_desc">清楚而明确地界定其假设、策略和治疗措施的特点</p>
                </div>
                <div class="home_info_block2_right_item">
                    <p class="item_title">提供了三阶段9步骤的操作模式</p>
                    <p class="item_desc">清晰地描述治疗过程中的步骤及关键点</p>
                </div>
                <div class="home_info_block2_right_item">
                    <p class="item_title">把情绪看做人类体验的原动力</p>
                    <p class="item_desc">清楚而明确地界定其假设、策略和治疗措施的特点</p>
                </div>
                <div class="home_info_block2_right_item">
                    <p class="item_title">临床实证效果明显，可操作性强</p>
                    <p class="item_desc">
                        强调对于情绪情感的表达、深层依恋需求的表达
                        产生更为深层的心灵互动，从而引发关系的改善
                    </p>
                </div>
            </div>
        </div>
        <!-- 第三块咨询师证言 -->
        <div class="home_info_block3" id="zxszyId">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">咨询师证言</h1>
            </div>
            <swiper
                v-if="!showSwiperForMobile"
                class="expert_swiper max_width_1200"
                :options="swiperOption"
            >
                <swiper-slide
                    v-for="(oueterItem, oueterIndex) in teacherListBox"
                    :key="oueterIndex"
                >
                    <div class="home_info_block3_card_box max_width_1200">
                        <template v-for="(item, index) in oueterItem.teacherList">
                            <div
                                :key="index"
                                class="home_info_block3_card"
                                :class="{'home_info_block3_card-hover': item === toVideoItem}"
                                @click="toVideo(item)"
                            >
                                <img class="video_back" :src="item.videoBack" alt=""/>
                                <div class="play-btn" :style="'background-image: url(' + playImg + ')'"></div>
                                <div class="card_top">
                                    <img class="avatar" :src="item.avatar" alt=""/>
                                    <div class="card_top_right">
                                        <h3 class="name">{{ item.name }}</h3>
                                        <p class="adddress">{{ item.address }}</p>
                                    </div>
                                </div>
                                <div class="card_center">
                                    <p>
                                        {{ item.desc }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </swiper-slide>
            </swiper>

            <swiper
                v-else
                class="expert_swiper max_width_1200"
                :options="swiperOption"
            >
                <swiper-slide
                    v-for="(item, oueterIndex) in teacherListMobile"
                    :key="oueterIndex"
                >
                    <div class="home_info_block3_card_box max_width_1200">
                        <div class="home_info_block3_card" :class="{'home_info_block3_card-hover': item === toVideoItem}" @click="toVideo(item)">
                            <img class="video_back" :src="item.videoBack" alt=""/>
                            <div class="play-btn" :style="'background-image: url(' + playImg + ')'"></div>
                            <div class="card_top">
                                <img class="avatar" :src="item.avatar" alt=""/>
                                <div class="card_top_right">
                                    <h3 class="name">{{ item.name }}</h3>
                                    <p class="adddress">{{ item.address }}</p>
                                </div>
                            </div>
                            <div class="card_center">
                                <p>
                                    {{ item.desc }}
                                </p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <!-- 第四块婚恋关系咨询师成长 -->
        <div class="home_info_block4" id="staId">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">
                    婚恋关系咨询师成长
                    <span class="top_title_active">专属入行计划</span>
                </h1>
                <p class="top_desc">
                    零基础，9个月成为婚恋关系专业心理咨询师<br/>
                    想做心理咨询师，当然选最吃香的婚恋咨询领域
                </p>
            </div>
            <div class="home_info_block4_content max_width_1200">
                <div class="home_info_block4_content_top">
                    <div class="content_top_left">
                        <div>
                            <img
                                @mouseenter="planEnter(1)"
                                @mouseleave="planLeave(1)"
                                class="content_top_left_img1"
                                src="../assets/img/jihua1.png"
                                alt=""
                            />
                        </div>
                        <div>
                            <img
                                @mouseenter="planEnter(2)"
                                @mouseleave="planLeave(2)"
                                class="content_top_left_img2"
                                src="../assets/img/jihua2.png"
                                alt=""
                            />
                        </div>

                        <div>
                            <img
                                @mouseenter="planEnter(3)"
                                @mouseleave="planLeave(3)"
                                class="content_top_left_img3"
                                src="../assets/img/jiahua3.png"
                                alt=""
                            />
                        </div>

                        <div>
                            <img
                                @mouseenter="planEnter(4)"
                                @mouseleave="planLeave(4)"
                                class="content_top_left_img4"
                                src="../assets/img/jihua4.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="content_top_right_outer_outer">
                        <div class="content_top_right_outer">
                            <div class="content_top_right">
                                <!-- 第一张图对应的字 -->
                                <transition name="zoom" mode="out-in">
                                    <div
                                        key="block1"
                                        v-if="planContentIndex == 1"
                                        :class="planContentIndex == 1 ? 'img1_content' : 'opacity0'"
                                    >
                                        <h3>理论</h3>
                                        <p>
                                            30课时婚姻家庭咨询进阶治疗技术 / ACT+情绪取向整合疗法技术
                                        </p>
                                        <p>婚恋创伤康复指导技术</p>
                                        <h3>练习</h3>
                                        <p>15小时婚姻家庭咨询进阶全流程咨询技能与练习</p>
                                        <h3>实践</h3>
                                        <p>
                                            40小时婚姻家庭咨询进阶交付技能训练 / 3阶段10步骤
                                            19个常见婚恋咨询场景联系
                                        </p>
                                        <p>12小时婚姻家庭经典案例实践 / 涵盖恋爱</p>
                                        <p>
                                            婚姻升温 出轨 修复 性咨询 暴力
                                            等典型婚恋咨询案例/28小时28个常见咨询卡点练习实践
                                        </p>
                                        <p>100小时真实情景咨询实习</p>
                                        <h3>收获</h3>
                                        <p>开始咨询执业 / 快速积累咨询时数 获得成长</p>
                                        <h3>收入</h3>
                                        <p>咨询费分成50% / 课程销售分成 / 训练营销售分成</p>
                                    </div>

                                    <!-- 第二张图对应的字 -->
                                    <div
                                        key="block2"
                                        v-else-if="planContentIndex == 2"
                                        :class="planContentIndex == 2 ? 'img1_content' : 'opacity0'"
                                    >
                                        <h3>护航计划理论</h3>
                                        <p>
                                            30课时婚姻家庭咨询进阶治疗技术 / ACT+情绪取向整合疗法技术
                                        </p>
                                        <p>婚恋创伤康复指导技术</p>
                                        <h3>练习</h3>
                                        <p>15小时婚姻家庭咨询进阶全流程咨询技能与练习</p>
                                        <h3>实践</h3>
                                        <p>
                                            40小时婚姻家庭咨询进阶交付技能训练 / 3阶段10步骤
                                            19个常见婚恋咨询场景联系
                                        </p>
                                        <p>12小时婚姻家庭经典案例实践 / 涵盖恋爱</p>
                                        <p>
                                            婚姻升温 出轨 修复 性咨询 暴力
                                            等典型婚恋咨询案例/28小时28个常见咨询卡点练习实践
                                        </p>
                                        <p>100小时真实情景咨询实习</p>
                                        <h3>收获</h3>
                                        <p>开始咨询执业 / 快速积累咨询时数 获得成长</p>
                                        <h3>收入</h3>
                                        <p>咨询费分成50% / 课程销售分成 / 训练营销售分成</p>
                                    </div>

                                    <!-- 第三张图对应的字 -->
                                    <div
                                        key="block3"
                                        v-else-if="planContentIndex == 3"
                                        :class="planContentIndex == 3 ? 'img1_content' : 'opacity0'"
                                    >
                                        <h3>远航计划理论</h3>
                                        <p>
                                            30课时婚姻家庭咨询进阶治疗技术 / ACT+情绪取向整合疗法技术
                                        </p>
                                        <p>婚恋创伤康复指导技术</p>
                                        <h3>练习</h3>
                                        <p>15小时婚姻家庭咨询进阶全流程咨询技能与练习</p>
                                        <h3>实践</h3>
                                        <p>
                                            40小时婚姻家庭咨询进阶交付技能训练 / 3阶段10步骤
                                            19个常见婚恋咨询场景联系
                                        </p>
                                        <p>12小时婚姻家庭经典案例实践 / 涵盖恋爱</p>
                                        <p>
                                            婚姻升温 出轨 修复 性咨询 暴力
                                            等典型婚恋咨询案例/28小时28个常见咨询卡点练习实践
                                        </p>
                                        <p>100小时真实情景咨询实习</p>
                                        <h3>收获</h3>
                                        <p>开始咨询执业 / 快速积累咨询时数 获得成长</p>
                                        <h3>收入</h3>
                                        <p>咨询费分成50% / 课程销售分成 / 训练营销售分成</p>
                                    </div>

                                    <!-- 第四张图对应的字 -->
                                    <div
                                        key="block4"
                                        v-else-if="planContentIndex == 4"
                                        :class="planContentIndex == 4 ? 'img1_content' : 'opacity0'"
                                    >
                                        <h3>独立执业理论</h3>
                                        <p>
                                            30课时婚姻家庭咨询进阶治疗技术 / ACT+情绪取向整合疗法技术
                                        </p>
                                        <p>婚恋创伤康复指导技术</p>
                                        <h3>练习</h3>
                                        <p>15小时婚姻家庭咨询进阶全流程咨询技能与练习</p>
                                        <h3>实践</h3>
                                        <p>
                                            40小时婚姻家庭咨询进阶交付技能训练 / 3阶段10步骤
                                            19个常见婚恋咨询场景联系
                                        </p>
                                        <p>12小时婚姻家庭经典案例实践 / 涵盖恋爱</p>
                                        <p>
                                            婚姻升温 出轨 修复 性咨询 暴力
                                            等典型婚恋咨询案例/28小时28个常见咨询卡点练习实践
                                        </p>
                                        <p>100小时真实情景咨询实习</p>
                                        <h3>收获</h3>
                                        <p>开始咨询执业 / 快速积累咨询时数 获得成长</p>
                                        <h3>收入</h3>
                                        <p>咨询费分成50% / 课程销售分成 / 训练营销售分成</p>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="home_info_block4_content_bot" id="asdfasdf">
                    <div class="block4_content_bot_card">
                        <div class="card_left">
                            <img class="card_img1" src="../assets/img/card1.png" alt=""/>
                        </div>
                        <div class="card_right">
                            <h2>针对婚恋关系的技术积累</h2>
                            <p>
                                亲密关系是心理咨询中最大的市场板块，也是咨询师最容易获得经济回报的板块。我们提供了亲密关系的专项咨询技术体系和成长路径，学员可以更快更专业地实现执业。
                            </p>
                            <p>
                                专为独立执业的亲密关系咨询场景设置的5步骤框架，让咨询师可以更标准更轻松地计划实施咨询方案让来访者快速得到并感受到到关系的改善，更获得个人成长，实现稳定幸福的关系。
                            </p>
                            <p>
                                我们提供了超过600个小时的专业理论教育，涵盖了基础心理学，系统婚姻家庭咨询，情绪取向咨询，人本、精分等核心咨询框架更教授包括ACTS-FBTMBCT等技术，应对不同的来访场景
                                我们教授包括OH卡、小人、婚姻地图等20多种咨询工具的应用，让咨询师更容易探索来访的底层心理，让资访互动变得更加简单、高效、准确。从而提升咨询的效率和效果。
                            </p>
                        </div>
                    </div>
                    <!-- 第二行卡片 -->
                    <div class="card_row">
                        <div class="block4_content_bot_card block4_content_bot_card2">
                            <div class="card_left">
                                <img class="card_img2" src="../assets/img/card2.png" alt=""/>
                            </div>
                            <div class="card_right">
                                <h2>更充分的实战演练</h2>
                                <p>
                                    心理咨询教育大多缺乏实战演练，导致学员不能应对实际的咨询场景。
                                </p>
                                <p>
                                    我们在每个成长阶段都为学员提供超过80小时的资深咨询师带练，确保学员能熟练应用各项咨询技术。
                                </p>
                            </div>
                        </div>
                        <div class="block4_content_bot_card block4_content_bot_card2">
                            <div class="card_left">
                                <img class="card_img2" src="../assets/img/card3.png" alt=""/>
                            </div>
                            <div class="card_right">
                                <h2>更充足的实践时数</h2>
                                <p>
                                    个案时数是衡量咨询师层级的最重要指标，大量的咨询师因为缺乏来访的资源，影响了资质升级的速度，甚至影响在行业的生存。
                                </p>
                                <p>
                                    我们在每个成长阶段都提供了超过100小时的个案实践，更通过教授自媒体技能，让学员具备获取个案的能力，减少对机构的依赖。
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- 第三行卡片 -->
                    <div class="card_row">
                        <div class="block4_content_bot_card block4_content_bot_card2">
                            <div class="card_left">
                                <img class="card_img2" src="../assets/img/card4.png" alt=""/>
                            </div>
                            <div class="card_right">
                                <h2>充分的证书背书</h2>
                                <p>
                                    完成训练计划可以获得包括中科院咨询师证书、人社部婚姻家庭咨询师中级证书、姚柳研究院情绪取向整合疗法中级证书。
                                </p>
                                <p>为个人执业特别是自媒体个人P推广提供充分的能力背书。</p>
                            </div>
                        </div>
                        <div class="block4_content_bot_card block4_content_bot_card2">
                            <div class="card_left">
                                <img class="card_img2" src="../assets/img/card5.png" alt=""/>
                            </div>
                            <div class="card_right">
                                <h2>从开始学习就有收入</h2>
                                <p>
                                    心理咨询师的成长路径的早期，一边需要投入学习积累，一边缺乏个案来源和收入，这让很多人半途而废。
                                </p>
                                <p>
                                    我们让学员从一开始就可以获得收入，提供包括自媒体，倾听，咨询，课程销售等多元化的收入来源，支持咨询师长远发展之路。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第五块自媒体 -->
        <div class="home_info_block5" id="zmtId">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">
                    自媒体<span class="top_title_active">获客技术</span>探索
                </h1>
                <p class="top_desc">
                    创始人IP视频号矩阵总播放量破亿，为平台咨询师提供大量来访资源 <br/>
                    成功为超过100位咨询师赋能，平均每位咨询师每月获得37个私信
                </p>
            </div>

            <div class="home_info_block5_content max_width_1200">
                <div class="block5_left">
                    <img src="../assets/img/huokejishu.png" alt=""/>
                    <p class="block5_left_bot">基于情绪的内容沟通</p>
                </div>
                <div class="block5_right">
                    <div class="block5_right_top">
                        <SwiperForHuoke
                            v-if="huokeList.length > 0"
                            :swiperList="huokeList"
                        ></SwiperForHuoke>
                    </div>

                    <div class="block5_right_bot">
                        <div
                            @click="handlerHuokeBtn(1)"
                            class="block5_right_bot_btn"
                            :class="huokeIndex == 1 ? 'btn_active' : ''"
                        >
                            创始人IP矩阵
                        </div>
                        <div
                            @click="handlerHuokeBtn(2)"
                            class="block5_right_bot_btn btn_right"
                            :class="huokeIndex == 2 ? 'btn_active' : ''"
                        >
                            咨询师自媒体赋能
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第六块 -->
        <div class="home_info_block6">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">
                    <span class="top_title_active">AI辅助</span>咨询
                </h1>
                <p class="top_desc">
                    与知名大学合作，基于临床大数据的模型训练
                    <br/>在咨询过程中提供多样化的智能技术支持，提升咨询效率和效果
                </p>
            </div>

            <div class="home_info_block6_content max_width_1200_1">
                <div class="block6_content_left">
                    <div>
                        <h2 class="left_title">咨询全过程赋能</h2>
                        <p class="left_desc">
                            提供实时技术辅助，让咨询师实现跨级别的咨询能力，
                            并更快获得经验积累和提升。更规范咨询框架，提升 咨询效率与效果。
                        </p>
                    </div>
                    <p class="left_desc">即将推出，敬请期待</p>
                </div>
                <div class="block6_content_right">
                    <img class="aifuzhu_img" src="../assets/img/aifuzhu11.png" alt=""/>
                </div>
            </div>
        </div>
        <!-- 第七块 -->
        <div class="home_info_block7" id="shzrId">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">
                    <span class="top_title_active">社会责任</span>
                </h1>
                <p class="top_desc">
                    深入社区、企业、学校
                    <br/>与妇联和国资委合作各种形式的公益活动
                </p>
            </div>
            <div
                class="home_info_block7_content_pc home_info_block7_content max_width_1200"
            >
                <div class="block7_content_left">
                    <div class="block7_content_left_top_row">
                        <div class="img_box1"></div>
                        <div class="img_box2">
                            <img src="../assets/img/zeren/zeren1.png" alt=""/>
                        </div>
                        <div class="img_box3">
                            <img src="../assets/img/zeren/zeren2.png" alt=""/>
                        </div>
                        <div class="img_box2">
                            <img src="../assets/img/zeren/zeren3.png" alt=""/>
                        </div>
                        <div class="img_box3">
                            <img src="../assets/img/zeren/zeren4.png" alt=""/>
                        </div>
                    </div>
                    <div class="block7_content_left_center_row">
                        <div class="img_box1"></div>
                        <div class="img_box1">
                            <img src="../assets/img/zeren/zeren5.png" alt=""/>
                        </div>
                        <div class="img_box1">
                            <img src="../assets/img/zeren/zeren6.png" alt=""/>
                        </div>
                        <div class="center_box">
                            <img src="../assets/img/centerImg.png" alt=""/>
                        </div>
                    </div>

                    <div class="block7_content_left_bot_row">
                        <div class="bot_row_column1">
                            <div class="img_box1"></div>
                        </div>
                        <div class="bot_row_column2">
                            <div class="img_box1">
                                <img src="../assets/img/zeren/zeren7.png" alt=""/>
                            </div>
                            <div class="img_box2"></div>
                        </div>
                        <div class="bot_row_column3">
                            <div class="img_box3">
                                <img src="../assets/img/zeren/zeren8.png" alt=""/>
                            </div>
                        </div>
                        <div class="bot_row_column4">
                            <div class="img_box1">
                                <img src="../assets/img/zeren/zeren9.png" alt=""/>
                            </div>
                            <div class="img_box2"></div>
                        </div>
                    </div>
                </div>

                <div class="block7_content_right">
                    <div class="block7_content_right_top_row">
                        <div class="right_top_row_column1">
                            <div class="img_box2">
                                <img src="../assets/img/zeren/zeren10.png" alt=""/>
                            </div>
                            <div class="img_box1">
                                <img src="../assets/img/zeren/zeren11.png" alt=""/>
                            </div>
                        </div>
                        <div class="right_top_row_column2">
                            <div class="img_box3">
                                <img src="../assets/img/zeren/zeren12.png" alt=""/>
                            </div>
                        </div>
                        <div class="right_top_row_column3">
                            <div class="img_box2"></div>
                        </div>
                    </div>
                    <div class="block7_content_right_center_row">
                        <div class="img_box2">
                            <img src="../assets/img/zeren/zeren13.png" alt=""/>
                        </div>
                        <div class="img_box1">
                            <img src="../assets/img/zeren/zeren14.png" alt=""/>
                        </div>
                        <div class="img_box1"></div>
                    </div>
                    <div class="block7_content_right_bot_row">
                        <div class="img_box1">
                            <img src="../assets/img/zeren/zeren15.png" alt=""/>
                        </div>
                        <div class="img_box1"></div>
                    </div>
                </div>
            </div>
            <!-- 手机端图片展示 -->
            <div
                class="home_info_block7_content home_info_block7_content_mobile max_width_1200"
            >
                <div class="home_info_block7_content_row">
                    <!-- <div class="img_box1"></div> -->
                    <div class="img_box3">
                        <img src="../assets/img/zeren/zeren2.png" alt=""/>
                    </div>
                    <div class="img_box2">
                        <img src="../assets/img/zeren/zeren3.png" alt=""/>
                    </div>
                    <div class="img_box3">
                        <img src="../assets/img/zeren/zeren4.png" alt=""/>
                    </div>
                </div>
                <div class="home_info_block7_content_row">
                    <!-- <div class="img_box1"></div> -->
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren5.png" alt=""/>
                    </div>
                    <div class="img_box2">
                        <img src="../assets/img/zeren/zeren1.png" alt=""/>
                    </div>
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren6.png" alt=""/>
                    </div>
                </div>
                <div class="home_info_block7_content_row">
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren7.png" alt=""/>
                    </div>
                    <div class="img_box2">
                        <img src="../assets/img/zeren/zeren10.png" alt=""/>
                    </div>
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren9.png" alt=""/>
                    </div>
                </div>
                <!-- 中间 -->
                <div class="home_info_block7_content_row">
                    <div class="center_box">
                        <img src="../assets/img/centerImg.png" alt=""/>
                    </div>
                </div>

                <div class="home_info_block7_content_row">
                    <div class="img_box3">
                        <img src="../assets/img/zeren/zeren8.png" alt=""/>
                    </div>
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren11.png" alt=""/>
                    </div>
                    <div class="img_box3">
                        <img src="../assets/img/zeren/zeren12.png" alt=""/>
                    </div>
                </div>

                <div class="home_info_block7_content_row">
                    <div class="img_box2">
                        <img src="../assets/img/zeren/zeren13.png" alt=""/>
                    </div>
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren14.png" alt=""/>
                    </div>
                    <div class="img_box1">
                        <img src="../assets/img/zeren/zeren15.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第八块 -->
        <div class="news-box" id="newsBox">
            <div class="top_title_box max_width_1200">
                <h1 class="top_title">
                    <span class="top_title_active">最新资讯</span>
                </h1>
                <div class="news-list-box">
                    <div class="news-list" v-for="m in top4news" :key="m.id">
                        <a class="news-list-wrap" :href="'/web/news/' + m.id">
                            <img :src="m.img" alt="" class="news-img">
                            <div class="news-type">{{ m.type }}</div>
                            <div class="news-title">{{ m.title }}</div>
                            <div class="news-subtitle">{{ m.subtitle }}</div>
                        </a>
                    </div>
                </div>
                <div class="latest-news-box">
                    <a class="latest-news" href="/web/news/">更多资讯</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoopSwiper from "../components/loopSwiper";
import SwiperForHuoke from "../components/SwiperForHuoke";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from 'axios';

export default {
    name: "home",
    components: {
        Swiper,
        SwiperSlide,
        LoopSwiper,
        SwiperForHuoke,
    },
    data() {
        return {
            playImg: require('../assets/img/play.png'),
            toVideoItem: {},
            huokeList: [],
            teacherListMobile: [],
            teacherListBox: [
                {
                    teacherList: [
                        {
                            name: "张馨月",
                            address: "湖北 武汉",
                            avatar: require("../assets/img/t/avatar1.png"),
                            videoBack: require("../assets/img/t/b1.jpg"),
                            desc: "哈佛大学有个研究的，说幸福其实就是一个和谐的关系，和谐的关系取决于什么呢？取决于我们的情绪，因为负向情绪会破坏我们的关系。通过今天讲课就更加深了，它更全面的就说，其实大部分的关系都来自于情绪。不管婆媳关系、什么性的问题，都来自这个情绪。所以怎么去认知、去表达，去解决这个情绪的问题，在我们这个恋爱关系当中是非常重要的。",
                            video: 'http://s8i3w9jwd.hn-bkt.clouddn.com/%E5%BC%A0%E9%A6%A8%E6%9C%88.mp4',
                        },
                        {
                            name: "杨秋叶",
                            address: "湖北 武汉",
                            avatar: require("../assets/img/t/avatar2.png"),
                            videoBack: require("../assets/img/t/b2.jpg"),
                            desc: "今天对情绪整合取向的疗法很收获，我们多了一个看待关系的视角，以前在我的咨询工作当中，我更多的适合就是个体做咨询，对于来访有关系议题，其实有欠缺的，这个疗法的一个优势，面对伴侣咨询，是能改变他们互动的生态的，我觉得这个是个体咨询很难去达到的一个点。",
                            video: 'http://s8i3w9jwd.hn-bkt.clouddn.com/%E6%9D%A8%E7%A7%8B%E5%8F%B6.mp4',
                        },
                        {
                            name: "彭呈方",
                            address: "广东 广州",
                            avatar: require("../assets/img/t/avatar3.png"),
                            videoBack: require("../assets/img/t/b3.jpg"),
                            desc: "这个核心的技术，是最关键的、最开始的理念，它的背后这样一种逻辑，能够非常清晰的了解，这样实际研究过程当中，能够把这个理论运用到实际当中，这个过程有非常深刻的、细致的感受，我本身是人本受训出身的，它跟人本共同的部分，都是共情、同理，很相通，我的部分就是它会聚焦到情绪的部分，通过看到关系背后的双方在这个当中的一些行为背后和深层的情绪，怎么样把一些情绪转化出来、接出来，当时双方都能够去看到彼此，然后去改变这样一个新的互动的模式。",
                            video: 'http://s8i3w9jwd.hn-bkt.clouddn.com/%E5%BD%AD%E5%91%88%E6%96%B9.mp4',
                        },
                        {
                            name: "别致",
                            address: "四川 成都",
                            avatar: require("../assets/img/t/avatar4.png"),
                            videoBack: require("../assets/img/t/b4.jpg"),
                            desc: "我感受到人真的是有很多的需求，不管这些需求是披着怎样的，可能很可怕或者很凶狠，或者是叫声很大的外衣进来的。但其实底下的生存需求是一致的。我比较感动的一个地方，就在于两个人，他们的情绪能够同时在一个咨询里面被看见，被呈现，能够有机会被表达，这就好像一个人忽然从一个充满矛盾的世界，好像来到了一个稍微能够平息一点的位置上面，那种情绪、内心的冲突能够得到回应，而且是那种及时的回应。我觉得这是非常难得的一个工作机会",
                            video: 'http://s8i3w9jwd.hn-bkt.clouddn.com/%E5%88%AB%E8%87%B4.mp4',
                        },
                    ],
                },
                // {
                //   teacherList: [
                //     {
                //       name: "陈思慧2",
                //       address: "湖北 武汉",
                //       avatar: require("../assets/img/avatar1.png"),
                //       videoBack: require("../assets/img/zhanwei1.png"),
                //       desc: "这个疗法简直太有诱惑力了，对于我这种新手咨询师来说，简直太合适了。因为它没有复杂的语言，也没有复杂的理论，所有东西都浅显易懂，易上手易操作，效果明显。而且它是专门研究亲密关系的一项疗法，如果你也和我一样对婚恋咨询情有独钟，请你一定了解一下情绪取向整合疗法。",
                //     },
                //     {
                //       name: "李小欢2",
                //       address: "湖北 武汉",
                //       avatar: require("../assets/img/avatar2.png"),
                //       videoBack: require("../assets/img/zhanwei2.png"),
                //       desc: "整合了各种理论和实践的情绪取向整合疗法，以情绪作为切入口，深入探索亲密关系双方依恋联结需求，带动伴侣关系走向良性互动，在婚恋治疗领域独树一帜，继往开来，以简明扼要、短程有效的专业幼师，让心理咨询师在清晰框架下进行咨询，特别适合新手入行推荐疗法，相信随着时间的推移，会有越来越多的咨询师把它应用于临床实践。",
                //     },
                //     {
                //       name: "周颖2",
                //       address: "广东 广州",
                //       avatar: require("../assets/img/avatar3.png"),
                //       videoBack: require("../assets/img/zhanwei3.png"),
                //       desc: "在含蓄的情感表达文化的中国，急需要引导亲密关系双方真实地表达彼此的需求、期待、深层依恋渴望让双方更明白对方想要的是一个什么样的伴侣，这非常重要。而情绪取向整合疗法恰恰就是这样一种疗法，有方法有步骤的引导双方慢慢进入心灵沟通层面，以寻求亲密感的联合，这种感觉和体验非常棒。",
                //     },
                //     {
                //       name: "李杰2",
                //       address: "四川 成都",
                //       avatar: require("../assets/img/avatar4.png"),
                //       videoBack: require("../assets/img/zhanwei4.png"),
                //       desc: "对于心理咨询师而言，最难得的是能够把复杂问题简单化，把简单问题复杂化。心理治疗的理论和技术通常都很高深，富含哲理，但再应对来访时需要把它变成大白话，用所有人都可以理解的方式表达清楚。而情绪取向整合疗法就是这样一个技术，她的哲学基础，理论框架，专业用词都非常通俗易懂，用起来简单高效，是我非常喜欢的一个疗法。",
                //     },
                //   ],
                // },
                // {
                //   teacherList: [
                //     {
                //       name: "陈思慧3",
                //       address: "湖北 武汉",
                //       avatar: require("../assets/img/avatar1.png"),
                //       videoBack: require("../assets/img/zhanwei1.png"),
                //       desc: "这个疗法简直太有诱惑力了，对于我这种新手咨询师来说，简直太合适了。因为它没有复杂的语言，也没有复杂的理论，所有东西都浅显易懂，易上手易操作，效果明显。而且它是专门研究亲密关系的一项疗法，如果你也和我一样对婚恋咨询情有独钟，请你一定了解一下情绪取向整合疗法。",
                //     },
                //     {
                //       name: "李小欢3",
                //       address: "湖北 武汉",
                //       avatar: require("../assets/img/avatar2.png"),
                //       videoBack: require("../assets/img/zhanwei2.png"),
                //       desc: "整合了各种理论和实践的情绪取向整合疗法，以情绪作为切入口，深入探索亲密关系双方依恋联结需求，带动伴侣关系走向良性互动，在婚恋治疗领域独树一帜，继往开来，以简明扼要、短程有效的专业幼师，让心理咨询师在清晰框架下进行咨询，特别适合新手入行推荐疗法，相信随着时间的推移，会有越来越多的咨询师把它应用于临床实践。",
                //     },
                //     {
                //       name: "周颖3",
                //       address: "广东 广州",
                //       avatar: require("../assets/img/avatar3.png"),
                //       videoBack: require("../assets/img/zhanwei3.png"),
                //       desc: "在含蓄的情感表达文化的中国，急需要引导亲密关系双方真实地表达彼此的需求、期待、深层依恋渴望让双方更明白对方想要的是一个什么样的伴侣，这非常重要。而情绪取向整合疗法恰恰就是这样一种疗法，有方法有步骤的引导双方慢慢进入心灵沟通层面，以寻求亲密感的联合，这种感觉和体验非常棒。",
                //     },
                //     {
                //       name: "李杰3",
                //       address: "四川 成都",
                //       avatar: require("../assets/img/avatar4.png"),
                //       videoBack: require("../assets/img/zhanwei4.png"),
                //       desc: "对于心理咨询师而言，最难得的是能够把复杂问题简单化，把简单问题复杂化。心理治疗的理论和技术通常都很高深，富含哲理，但再应对来访时需要把它变成大白话，用所有人都可以理解的方式表达清楚。而情绪取向整合疗法就是这样一个技术，她的哲学基础，理论框架，专业用词都非常通俗易懂，用起来简单高效，是我非常喜欢的一个疗法。",
                //     },
                //   ],
                // },
            ],
            expertList: [
                {
                    name: "郑日昌教授",
                    img: require("../assets/img/experts/1.jpg"),
                    desc: "北京师范大学心理系教授,博士生导师,中国心理卫生协会常务理事",
                    textBgColor: '#f1ddde',
                },
                {
                    name: "史占彪教授",
                    img: require("../assets/img/experts/2.jpg"),
                    desc: "中国科学院心理研究所教授,博士生导师,中科院心理所心理咨询社创始人",
                    textBgColor: '#bcccff',
                },
                {
                    name: "葛操教授",
                    img: require("../assets/img/experts/3.jpg"),
                    desc: "中国心理学会注册督导师郑州大学心理学教授,博士生导师",
                    textBgColor: '#f1ddde',
                },
                {
                    name: "朱浩亮教授",
                    img: require("../assets/img/experts/4.jpg"),
                    desc: "温州大学心理学教授,硕士生导师,中国心理卫生协会心理咨询师专业委员会委员",
                    textBgColor: '#bcccff',
                },
                {
                    name: "顾俊峰主任",
                    img: require("../assets/img/experts/5.jpg"),
                    desc: "中国心理干预技术协会常务理事,浙江省社会心理学会理事兼培训中心主任",
                    textBgColor: '#e1efef',
                },
                {
                    name: "廖传景教授",
                    img: require("../assets/img/experts/6.jpg"),
                    desc: "温州大学教授,心理学博士,全国大学生心理咨询专业委员会委员",
                    textBgColor: '#f1ddde',
                },
                {
                    name: "李敏老师",
                    img: require("../assets/img/experts/7.jpg"),
                    desc: "ICEEFT国际情绪取向中心认证咨询师,华中师范大学心理学硕士",
                    textBgColor: '#bcccff',
                },
                {
                    name: "申永磊",
                    img: require("../assets/img/experts/8.jpg"),
                    desc: "高级心理健康指导师,国家二级心理咨询师",
                    textBgColor: '#e1efef',
                },
            ],
            showSwiperForMobile: false,
            planContentIndex: 1,
            swiperOption: {
                loop: false,
                autoplay: {
                    delay: 5000,
                },
                slidesPerView: "auto",
                speed: 1000,
            },
            screenWidth: 2048,
            huokeIndex: 1, //获客按钮标志
            top4news: [],
        };
    },
    created() {
        axios.get('https://yaoliujiaoyu.com/web/news-top4').then(({ data }) => {
            this.top4news = data;
        });
    },
    mounted() {
        //获取屏幕尺寸
        this.screenWidth = document.body.clientWidth;
        window.onresize = () => {
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })();
        };

        this.huokeList = [
            {img: require("../assets/img/douyin/1.jpg")},
            {img: require("../assets/img/douyin/2.jpg")},
            {img: require("../assets/img/douyin/3.jpg")},
            {img: require("../assets/img/douyin/4.jpg")},
        ];
    },
    watch: {
        teacherListBox: {
            handler(newValue, oldValue) {
                this.getTeacherListMobile();
            },
            deep: true,
            immediate: true,
        },
        screenWidth: function (n, o) {
            if (n <= 850) {
                this.showSwiperForMobile = true;
            } else {
                this.showSwiperForMobile = false;
            }
        },
    },
    methods: {
        // 手机端咨询师列表
        getTeacherListMobile() {
            let that = this;
            this.teacherListMobile = [];
            this.teacherListBox.forEach((item) => {
                if (item.teacherList) {
                    item.teacherList.forEach((element) => {
                        this.teacherListMobile.push(element);
                    });
                }
            });
        },
        planEnter(hase) {
            this.planContentIndex = hase;
        },
        planLeave(hase) {
            this.planContentIndex = 1;
        },
        toVideo(item) {
            if (this.toVideoItem === item) {
                window.open(
                    item.video,
                    "_blank"
                );
            } else {
                this.toVideoItem = item;
            }
        },
        // 获客按钮点击
        handlerHuokeBtn(hase) {
            this.huokeIndex = hase;
            if (hase == 1) {
                this.huokeList = [
                    {img: require("../assets/img/douyin/1.jpg")},
                    {img: require("../assets/img/douyin/2.jpg")},
                    {img: require("../assets/img/douyin/3.jpg")},
                    {img: require("../assets/img/douyin/4.jpg")},
                ];
            } else {
                this.huokeList = [
                    {img: require("../assets/img/q/1.jpg")},
                    {img: require("../assets/img/q/2.jpg")},
                    {img: require("../assets/img/q/3.jpg")},
                    {img: require("../assets/img/q/4.jpg")},
                ];
            }
        },
    },
};
</script>

<style lang="less" scoped>
.news-list-box {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
}
.news-list {
    width: 50%;
    flex-shrink: 0;
}
.news-list-wrap {
    padding: 20px;
    display: block;
    text-decoration: none;
    color: inherit;
}
.news-img {
    display: block;
    width: 100%;
    height: 320px;
    object-fit: cover;
    border-radius: .5rem;
}
.news-type {
    font-size: 1.3rem;
    margin: 20px 0 0;
}
.news-title {
    color: #ff967c;
    font-size: 1.875rem;
    font-weight: 700;
    margin: 10px 0;
}
.news-subtitle {
    font-size: 1.625rem;
    color: #484971;
}
@media (max-width: 768px) {
    .news-list {
        width: 100%;
    }
    .news-img {
        height: 200px;
    }
    .news-type {
        font-size: 1rem;
    }
    .news-title {
        font-size: 1.3rem;
    }
    .news-subtitle {
        font-size: 1.1rem;
    }
}
.latest-news-box {
    padding: 14px 0;
}
.latest-news {
    font-size: 22px;
    font-weight: 700;
    color: #7375d9;
    display: block;
}
.to-news-tips {
    margin: 6px 0 0;
    text-align: center;
    font-size: 12px;
    color: #666;
}
@import url("@/assets/css/home.less");
</style>
