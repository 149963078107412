/**
 * 服务器路径 
 */
export const BASE_URL = "";

/**
 * 富文本前缀
 */
export const RICH_URL = "";

/**
 * 统一登录页地址
 */
export const LOGIN_URL = "http://localhost:8080/login";


